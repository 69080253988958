<template>
  <div :class="$style.container">
    <div :class="$style.proofBox" v-if="!timeout">
      <!-- <p>{{ $t('matterwebset.screenmanage.screentext') }}</p> -->
      <p>智能安全防护平台运维大屏</p>
      <div v-if="isNeedProof" :class="$style.inputBox">
        <a-input
          @keydown.enter="checkUrl"
          :class="$style.input"
          v-model="password"
          @change="input"
          placeholder="请输入密码"
        >
          <a-icon slot="prefix" type="lock" />
        </a-input>
        <a-button
          type="primary"
          :class="[$style.button, password.length === 4 ? $style.active : '']"
          @click="checkUrl"
          >确定</a-button
        >
      </div>
      <div :class="[errorMes ? $style.showMes : '', $style.errorMes]">
        {{ errorMes }}
      </div>
    </div>
    <div :class="[$style.proofBox, $style.outTimeBox]" v-else>
      <p>来晚了~分享的链接已失效</p>
    </div>
  </div>
</template>

<script>
import '@/assets/css/reset.css';
import { Component, Vue } from 'vue-property-decorator';
import { checkUrl } from '@/services/things/screen-manage';
import { getShareCameraViewMode } from '@/services/monitor/camera-view-list.js';
import { matchDevice } from '@/assets/js/util.js';
import { flexDpr } from '@/assets/js/flex-dpr.js';
import { createModuleUrl } from '@/utils';

/** @name 分享的类型 */
const KEY_MODEL = {
  /** @name 监控视图 */
  MODEL_CAMERA: '监控视图',
  /** @name 大屏 */
  MODEL_SCREEN: '大屏',
  /** @name 项目 */
  MODEL_PROJECT: '项目',
};

/**
 * @name 路由匹配
 */
const source = {
  monitorProof: {
    name: KEY_MODEL.MODEL_CAMERA,
    hasMobile: false,
  },
  proof: {
    name: KEY_MODEL.MODEL_SCREEN,
    hasMobile: true,
  },
  projectProof: {
    name: KEY_MODEL.MODEL_PROJECT,
    hasMobile: true,
  },
};

flexDpr();
@Component({
  created() {
    const id = this.$route.params.id;
    if (matchDevice() && !matchDevice('Tablet')) {
      let link = `${createModuleUrl(
        'm',
      )}/#/pages/iot/shareInputPassword/index?id=${id}`;
      if (this.$route.query.t) {
        link += `&t=${this.$route.query.t}`;
      }

      /**@name 是否需要跳转移动端 */
      this.source.hasMobile && window.location.replace(link);
    } else {
      this.checkUrl();
    }
  },
})
export default class Proof extends Vue {
  get source() {
    const path = this.$route.path;
    let result = '';
    Object.keys(source).forEach(v => {
      if (path.includes(v)) {
        result = source[v];
      }
    });
    return result;
  }

  password = '';
  isNeedProof = false;
  timeout = false;
  errorMes = '';
  flag = null;
  isProjectProof = this.$route.name === 'projectProof';
  /**
   * @name 密码锁定只能用字母+数字4位
   */
  input({ target: { value } }) {
    value = value.replace(/[^a-zA-Z0-9]/g, '');
    value = value.substring(0, 4);
    this.password = value;
  }
  showMes(val) {
    this.errorMes = val;
    this.flag && clearTimeout(this.flag);
    this.flag = setTimeout(() => (this.errorMes = ''), 3000);
  }
  async checkUrl() {
    if (this.isNeedProof && this.password.length < 4) {
      this.showMes(this.password.length ? '密码长度应为4' : '请输入密码');
      return;
    }
    try {
      const id = this.$route.params.id;
      const data = { id, password: this.password };
      let visitorInfo = {};
      switch (this.source.name) {
        case KEY_MODEL.MODEL_CAMERA:
          await getShareCameraViewMode({
            shareId: data.id,
            password: this.password,
          });
          visitorInfo = { ...data, url: this.$route.path };
          break;
        case KEY_MODEL.MODEL_PROJECT:
          // if (this.$route.query.t === 'backend') {
          //   /** @name 后台管理-电箱管理-查看项目 */
          //   visitorInfo = await getBackendCheckUrl(data);
          // } else {
          //   visitorInfo = await checkUrl(data);
          // }
          visitorInfo = await checkUrl(data);
          break;
        case KEY_MODEL.MODEL_SCREEN:
          visitorInfo = await checkUrl(data);
          break;
        default:
          visitorInfo = await checkUrl(data);
          break;
      }
      this.$store.dispatch('generalScreen/setVisitorInfo', visitorInfo);

      this.handleLink();
    } catch (e) {
      if (e.code === 10001) {
        this.isNeedProof ? this.showMes(e.message) : (this.isNeedProof = true);
      } else if (e.code === 10002 || e.code === 10031) {
        this.timeout = true;
      }
    }
  }

  async handleData() {
    // eslint-disable-next-line no-useless-catch
    try {
      const id = this.$route.params.id;
      const data = { id, password: this.password };
      let visitorInfo = {};
      switch (this.source.name) {
        case KEY_MODEL.MODEL_CAMERA:
          await getShareCameraViewMode({
            shareId: data.id,
            password: this.password,
          });
          break;
        case KEY_MODEL.MODEL_PROJECT:
          // if (this.$route.query.t === 'backend') {
          //   /** @name 后台管理-电箱管理-查看项目 */
          //   visitorInfo = await getBackendCheckUrl(data);
          // } else {
          //   visitorInfo = await checkUrl(data);
          // }
          visitorInfo = await checkUrl(data);
          break;
        case KEY_MODEL.MODEL_SCREEN:
          visitorInfo = await checkUrl(data);
          break;
        default:
          visitorInfo = await checkUrl(data);
          break;
      }
      this.$store.dispatch('generalScreen/setVisitorInfo', visitorInfo);
    } catch (error) {
      throw error;
    }
  }

  handleLink() {
    switch (this.source.name) {
      case KEY_MODEL.MODEL_CAMERA:
        this.$router.push({
          path: `/iot/shareCamera/${this.$route.params.id}`,
        });
        break;
      case KEY_MODEL.MODEL_PROJECT:
        this.$router.push({ path: '/iot/screen/projectShare' });
        break;
      case KEY_MODEL.MODEL_SCREEN:
        this.$router.push({ path: '/iot/generalScreen' });
        break;
      default:
        this.$router.push({ path: '/iot/generalScreen' });
        break;
    }
  }
}
</script>

<style lang="less" module>
.container {
  width: 100vw;
  height: 100vh;
  background-image: url('../../../assets/images/iot-screen-manage/share-screen-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  .proofBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    p {
      color: #05d3ff;
      text-align: center;
      font-size: 0.48rem;
      margin-bottom: 0.5rem;
    }
    .inputBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: content-box;
      box-shadow: 0rem 0rem 0.18rem 0.02rem rgba(12, 181, 232, 0.4) inset;
      background: linear-gradient(#07d3fd, #07d3fd) left top,
        linear-gradient(#07d3fd, #07d3fd) left top,
        linear-gradient(#07d3fd, #07d3fd) right top,
        linear-gradient(#07d3fd, #07d3fd) right top,
        linear-gradient(#07d3fd, #07d3fd) left bottom,
        linear-gradient(#07d3fd, #07d3fd) left bottom,
        linear-gradient(#07d3fd, #07d3fd) right bottom,
        linear-gradient(#07d3fd, #07d3fd) right bottom;
      background-repeat: no-repeat;
      background-size: 0.02rem 0.2rem, 0.2rem 0.02rem;
      padding: 0.2rem;
    }
    .input {
      width: 3.4rem;
      height: 0.54rem;
      border-radius: 5px;
      border-color: #0884b9;
      background-color: #fff;
      :global(.ant-input) {
        border: none;
        height: 100%;
      }
    }
    .button {
      margin-left: 0.2rem;
      height: 0.54rem;
      width: 1.48rem;
      background-color: #0884b9;
      border-color: #0884b9;
      font-size: 0.18rem;
      font-weight: bold;
      &.active {
        background-color: #0bb3fb;
      }
    }
  }
  .outTimeBox {
    width: 6rem;
  }
  .errorMes {
    max-height: 0;
    position: absolute;
    transition: all 200ms linear;
    bottom: -0.3rem;
    font-size: 0.18rem;
    font-weight: 600;
    color: var(--delete);
    overflow: hidden;
  }
  .showMes {
    max-height: 0.2rem;
  }
}
</style>
